import React, { useState, createRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';



const carouselData = [
    {
        model:'Lamborghini Huracan Spider',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/huracan-spyder.png',
        nodeRef:createRef(null),
        cc:'5.204',
        cv:'610',
        zero:'3.45',
        speed:'324'
    },
    {
        model:'Lamborghini Urus',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/lamborghini-urus.png',
        nodeRef:createRef(null),
        cc:'3.996',
        cv:'666',
        zero:'3.5',
        speed:'305'
    },
    {
        model:'Lamborghini Urus Performante',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/Lamborghini-Urus-Performante.png',
        nodeRef:createRef(null),
        cc:'3.996',
        cv:'666',
        zero:'3.3',
        speed:'306'
    },
    {
        model:'Ferrari Portofino',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/ferrari-portofino.png',
        nodeRef:createRef(null),
        cc:'3.855',
        cv:'600',
        zero:'3.5',
        speed:'320'
    },
    {
        model:'Ferrari 296 GTB',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/Ferrari-296-GTB.png',
        nodeRef:createRef(null),
        cc:'2.992',
        cv:'663',
        zero:'2.9',
        speed:'330'
    },
    {
        model:'Ferrari F8 Tributo',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/ferrari-f8-tributo.png',
        nodeRef:createRef(null),
        cc:'3.902',
        cv:'720',
        zero:'2.9',
        speed:'340'
    },
    {
        model:'Mercedes Benz G63 AMG',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/mercedes-benz-g63-amg.png',
        nodeRef:createRef(null),
        cc:'3.982',
        cv:'585',
        zero:'4.4',
        speed:'220'
    },
    {
        model:'Porche Macan',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/porche-machan.png',
        nodeRef:createRef(null),
        cc:'2.894',
        cv:'380',
        zero:'4.8',
        speed:'259'
    },
    {
        model:'Porche 992',
        img:'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/porsche-992.png',
        nodeRef:createRef(null),
        cc:'2.981',
        cv:'385',
        zero:'4.2',
        speed:'293'
    }
]

const Carousel = () => {
  const [item,setItem] = useState(0);
  return (
    <div className='row' style={{width:'80vw', height:'20vw',justifyContent:'space-between',overflow:'hidden'}}>
         <div className='row carouselBtn' style={{margin:'0 0 0 2vw'}}  onClick={()=>{item>0?setItem(item-1):setItem(8)}}>
                    prev
                    </div>
                    <SwitchTransition>
          
            <CSSTransition
            
              key={carouselData[item].model}
              nodeRef={carouselData[item].nodeRef}
              timeout={400}
              classNames="rentCarousel"
              mountOnEnter
              unmountOnExit
            >
                <div ref={carouselData[item].nodeRef} className='row relative'>
                    <img style={{width:'30vw',height:'auto'}} src={carouselData[item].img} alt={carouselData[item].model}/>
                    <div className='column' style={{margin:'0 0 0 2vw',alignSelf:'flex-start',width:'25vw',alignItems:'flex-start'}}>
                        <p style={{fontSize:'2vw',color:'#ff2c02',fontWeight:'600'}}>{carouselData[item].model}</p>
                        <p style={{margin:'0.2vw 0',fontSize:'1.2vw'}}>cilindrata: <span style={{color:'#ff2c02'}}>{carouselData[item].cc}</span> cc</p>
                        <p style={{margin:'0.2vw 0',fontSize:'1.2vw'}}>cavalli: <span style={{color:'#ff2c02'}}>{carouselData[item].cv}</span> hp</p>
                        <p style={{margin:'0.2vw 0',fontSize:'1.2vw'}}>accelerazione 0-100Km/h: <span style={{color:'#ff2c02'}}>{carouselData[item].zero}</span> s</p>
                        <p style={{margin:'0.2vw 0',fontSize:'1.2vw'}}>velocità max: <span style={{color:'#ff2c02'}}>{carouselData[item].speed}</span> Km/h</p>
                    </div>
                </div>
              
            </CSSTransition>
            </SwitchTransition>
          
        
                    
        

        <div className='row carouselBtn' style={{margin:'0 2vw 0 0'}} onClick={()=>{item<8?setItem(item+1):setItem(0)}}>
                    next
                    </div>
    </div>
  )
}

export default Carousel;