import React from 'react'
import Header from '../../functional/deskHeader-mobile';

import Description from '../../functional/deskription-mobile-section';
import FullTripleImg from '../../functional/fullImage-mobile';
import zero from '../../../assets/rent_1.jpg';
import one from '../../../assets/rent_2.jpg';
import Whats from '../../functional/customWhats';
import Services from '../../functional/pointServices_mob';
import Carousel from './carousel';
const Mobile =()=> {
  
    
    return (
      <div className='relative column' style={{overflowX:'hidden'}}>
       <Header text={'NOLEGGIO'}/>
         <video style={{width:'100vw',height:'100vw',objectFit:'cover',margin:'0'}}
               crossOrigin="*"
               autoPlay
               muted
               loop>
                <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rent_mobile.mp4' type="video/mp4" />

        </video>
        <Description 
               margin={'2vw 0 0 0'}
               w={'80vw'}
               align={'justify'}
               title={'PROFESSIONISTI DELLA GUIDA SPORTIVA AL TUO FIANCO PER LA TUA AUTO A NOLEGGIO'}
               text={`RS MOTORS offre servizi di noleggio a breve e lungo termine per tutte le categorie di auto su tutto il territorio nazionale`}
               text1={`Dalla supercar al SUV fino alla citycar, il nostro parco macchine è in grado di soddisfare qualsiasi esigenza`}
               />
               <Carousel />

               <Whats svgStyle={{width:'15vw',fill:'#25d366',margin:'0 1vw 0 2.5vw'}} 
                      text={'RICHIEDI INFORMAZIONI'}
                      pStyle={{fontSize:'5vw',fontWeight:'700',margin:'0 2.5vw 0 0'}}
                      parHeight={'5vw'}
                      mainMargin={'0 0 10vw 0'} />

               <Services serviceNames={[
              'noleggio supercars',
              'noleggio lungo termine',
              'noleggio con conducente',
              'staff operativo 24/7',
              'consegna su tutto il territorio nazionale',
              'noleggio per eventi speciali, film, matrimoni']}  />
              
        <FullTripleImg 
        imageUrl={zero} 
        imgStyle={{objectFit:'cover',height:'110vw',transform:'skewY(20deg) translateY(-5vw)'}}
        image2Url={one} 
        img2Style={{objectFit:'cover',height:'100vw',transform:'skewY(20deg)'}}
        />
      </div>
      
    )
  }

export default Mobile;